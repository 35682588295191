<script setup lang="ts">
import { defineProps } from 'vue';

const props = withDefaults(
  defineProps<{
    level: number;
  }>(),
  {
    level: 1,
  },
);
const intervalRef = ref<NodeJS.Timeout | null>(null);
const imageSrc = ref(`https://cdn.weolbu.com/data_file/lev${props.level}_final/0001.png`);

onMounted(() => {
  let count = 1;
  intervalRef.value = setInterval(() => {
    if (count > 30 && intervalRef.value) {
      clearInterval(intervalRef.value);
      return;
    }
    const nextImageNumber = count.toString().padStart(4, '0');
    const nextImageSrc = `https://cdn.weolbu.com/data_file/lev${props.level}_final/${nextImageNumber}.png`;
    const img = new Image();
    img.onload = () => {
      imageSrc.value = nextImageSrc;
    };
    img.src = nextImageSrc;

    count++;
  }, 100);
});

onUnmounted(() => {
  if (intervalRef.value) {
    clearInterval(intervalRef.value);
  }
});
</script>

<template>
  <img :src="imageSrc" alt="레벨 뱃지 이미지" width="64" height="64" />
</template>

<style scoped></style>
